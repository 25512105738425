import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    LeadParaTwo:
      'Since necessity is the mother of invention, almost every company in the world has taken a deep look at their technology systems over the past year to understand if they’re optimally positioned to answer the question, “how and where are we spending our money?”.',
    LeadParaThree:
      'These challenges have also increased the complexities for CFO’s, Procurement leaders, CIO’s, and numerous other internal stakeholders who are responsible for delivering cost savings and risk mitigation to ensure the long-term financial and operational health of their company.  In today’s business environment, the demand to deliver on these obligations are frequently met with fewer resources and thinner budgets.',
    LeadParaFour:
      'While the requirements for corporate leaders are to reduce cost, mitigate risk, expand their respective functions, all while focusing on increasing their company’s value, it’s become clear the ability to execute on these critical missions is a different story when faced with inadequate spend management technology solutions.  Using the Procurement space as an example, keeping up with the continuous demands to source and optimize existing expense line items, and properly support new investments is a major challenge when faced with numerous point-solution technologies.  Various integrations across multiple systems is directly responsible for data loss and spend leakage.',
    LeadParaFive:
      'As we’ve seen over the past year, the challenges and expectations from Finance, Sourcing, IT, and other corporate organizations have increased to support their newly decentralized workforces.  Inject issues like global trade uncertainty, increasing tariff pressures, and external factors like chip shortages and logistics bottlenecks, and Procurement organizations have been forced to be more strategic and predictive than ever before.  How is this done?  It all starts with good planning, and the technology to help drive proper spend management visibility with respect to the procuring of goods and services.',
    LeadParaSix:
      'As per Deloitte’s 2021 CPO’s (Chief Procurement Officer) survey, tackling the increased scope of responsibilities and complexities requires CPO’s to hinge upon one key initiative: digital transformation.  To support different corporate functions, CPO’s are investing in the digitization and move up the value-chain in their own technologies and processes to face these complexities, while providing value to their internal business stakeholders, and driving corporate operational and strategic value.',
    LeadParaSeven:
      'Here is where Raindrop provides the ability to solve these critical needs.  Raindrop’s industry-disruptive spend management platform is built from the ground up as a SaaS solution with Artificial Intelligence, Machine Learning, and blockchain technologies, capable of rapid deployment and a light footprint.  Raindrop’s Source-to-Pay (S2P) technology differentiate itself from traditional market solutions via automation capabilities and a highly flexible user interface, enabling each business stakeholder to run your business in your own unique way, all via a powerful single pane-of-glass platform.  Raindrop can also be immediately deployed and operational within minutes, and only requires a web browser to run.  Standard API connectors to numerous ERP, SSO, HRIS, and other systems all help to deliver immediate attainment to value and visibility like no other solution in the industry can do.',
    HeadingOne: 'Collaborative & Secure Sourcing Solution',
    LeadParaEight:
      'Raindrop is designed by Procurement industry practitioners who have lived firsthand the challenges the companies they’ve worked at face. Raindrop ties together all the technologies for effective spend management into a Procurement Resources Management that speaks your language. Raindrop does not take a one-size-fits-all approach. You can tailor dashboards, alerts, workflows, supplier scorecards, reports, and more, to meet your unique needs. Deploy the full suite, or just the modules you need right now. Raindrop will grow with you.',
    LeadParaNine:
      'As a new generation spend management platform, Raindrop enables company leaders, whether they’re CFO’s, CIO’s, CPO’s, or others, to fully gain spend visibility in order to realize cost containment and increased value to their companies.',
    LeadParaTen: 'Raindrop can deliver the following highlights –',
    LeadParaTenSubPointOne:
      'Digitizing your procurement data which enables spend leakage – e.g., surprises from automatic contract renewal',
    LeadParaTenSubPointTwo: 'Understand your Sourcing pipeline and help prioritize them.',
    LeadParaTenSubPointThree:
      'Machine Learning algorithms to predict savings and risks and frictionless collaboration between internal teams and suppliers',
    LeadParaTenSubPointFour:
      'Automation and workflow capabilities which help reduce the cost of delivering procurement services',
    LeadParaTenSubPointFive:
      '360-degree view on suppliers that shows everything you need to know company details, contracts, spend analysis, upcoming events, and news. A configurable scorecard gathers feedback from your team with historical trending.',
    LeadParaTenSubPointSix: 'Seamlessly integrating your fragmented (or) legacy systems',
    LeadParaTenSubPointSeven: 'Provides a 360-degree view across your planning, spend, sourcing, and contracts',
    LeadParaTenSubPointEight:
      'With Raindrop’s automation toolkit, you can recover time to do more strategic activities',
    Icon: <FaArrowCircleRight />,
  },
]
