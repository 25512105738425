import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppCorporateRest } from './AppCorporateRest'

const AppCorporateWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CorporateSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .CorporateNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .CorporateSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppCorporateWithNav = () => {
  return (
    <AppCorporateWithNavWapper>
      <div className='CorporateSection'>
        <div className='CorporateNav'>
          <AppSideNavEmailName />
        </div>
        <div className='CorporateSectionContent'>
          <AppCorporateRest />
        </div>
      </div>
    </AppCorporateWithNavWapper>
  )
}
