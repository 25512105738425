import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import CorporateRestData from '../Data/CorporateRestData'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppCorporateRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContentSectionEmailProcurement {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .LeadParaStyleCorporateOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1450px) {
      max-width: 800px;
    }
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaStyleCorporate {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .CorporateRestHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
`

export const AppCorporateRest = () => {
  return (
    <AppCorporateRestWapper>
      <div>
        {CorporateRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='ContentSectionEmailProcurement'>
                <ReUsePtag para={data.LeadParaTwo} paraStyle='LeadParaStyleCorporateOne' />
                <ReUsePtag para={data.LeadParaThree} paraStyle='LeadParaStyleCorporate' />
                <ReUsePtag para={data.LeadParaFour} paraStyle='LeadParaStyleCorporate' />
                <ReUsePtag para={data.LeadParaFive} paraStyle='LeadParaStyleCorporate' />
                <ReUsePtag para={data.LeadParaSix} paraStyle='LeadParaStyleCorporate' />
                <ReUsePtag para={data.LeadParaSeven} paraStyle='LeadParaStyleCorporate' />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='CorporateRestHeadingEmail' />
                <ReUsePtag para={data.LeadParaEight} paraStyle='LeadParaStyleCorporate' />
                <ReUsePtag para={data.LeadParaNine} paraStyle='LeadParaStyleCorporate' />
                <ReUsePtag para={data.LeadParaTen} paraStyle='LeadParaStyleCorporate' />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointOne}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointTwo}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointThree}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointFour}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointFive}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointSix}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointSeven}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.LeadParaTenSubPointEight}
                  paraStyle='LeadParaStyleCorporate'
                  IconStyle='iconColor'
                />
              </div>
            </div>
          )
        })}
      </div>
    </AppCorporateRestWapper>
  )
}
