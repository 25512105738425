import React from 'react'
import { AppResourcesEmailBanner } from './AppResourcesEmailBanner'
import CorporateBannerData from '../Data/CorporateBannerData.json'
import styled from 'styled-components'

const AppCorporateBannerWapper = styled.div`
  .CorporateSection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .CorporateImageSection {
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .CorporateHeading {
    max-width: 940px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .CorporatePara {
    max-width: 850px;
    padding: 15px 30px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .DateCorporatePara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .CorporateBannerImage {
    width: 100%;
    height: auto;
  }
  .DisplayNone {
    display: none;
  }
`

export const AppCorporateBanner = () => {
  return (
    <AppCorporateBannerWapper>
      {CorporateBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailBanner
              Heading={data.Title}
              Para={data.Leadpara}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              LeadParaStyle='DisplayNone'
              BannerHeading='CorporateHeading'
              BannerPara='CorporatePara'
              DateBannerPara='DateCorporatePara'
              BannerImageSection='CorporateImageSection'
              BannerImage='CorporateBannerImage'
              BannerSection='CorporateSection'
            />
          </div>
        )
      })}
    </AppCorporateBannerWapper>
  )
}
